<template>
  <b-modal
    id="modal-share-public-model"
    title="Publicly Share Model"
    ok-title="Share model with ALL users"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="outline-secondary"
    @ok="shareModel"
  >
    <p>
      Setting this model to be shared publicly will allow any logged in user to
      <span class="font-weight-bold text-warning">access and modify</span>
      this model.
    </p>
    <p class="mt-1 font-weight-bold text-warning">
      Do you wish to proceed?
    </p>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PubliclyShareModel',
  methods: {
    shareModel() {
      const { modelId } = this.$route.params
      coreService.modelApi.updateModelProperties(modelId, {public: true})
        .then(response => {
          this.$store.commit('model/setPublicShareState', true)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model access set to PUBLIC',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(data => {
          console.error(data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to set model to public access',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
