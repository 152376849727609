<template>
  <b-modal
    id="modal-delete-model"
    centered
    title="Are you absolutely sure?"
    @show="onShow"
    @ok="onDelete"
  >
    <b-alert show variant="warning" class="mb-2">
      <div class="d-inline-flex w-100 justify-content-start mb-50">
        <font-awesome-icon
          :icon="['fa', 'fa-exclamation-triangle']"
          class="text-warning font-large-2 mx-2 mt-50"
        />
        <p class="mb-0 mt-50">
          This action deletes the current model and
          <span class="font-weight-bolder">everything</span>
          it contains.
        </p>
      </div>
    </b-alert>
    <h6 class="mb-1">
      You are about to delete this Model containing:
    </h6>
    <ul>
      <li>{{ requirementsCount }} requirement{{ requirementsCount | handlePluralSuffix }}</li>
      <li>{{ behaviourNodesCount }} behaviour node{{ behaviourNodesCount | handlePluralSuffix }}</li>
      <li>{{ issuesCount }} issue{{ issuesCount | handlePluralSuffix }}</li>
      <li>{{ testsCount }} test case{{ testsCount | handlePluralSuffix }}</li>
    </ul>

    <p class="mb-0">
      Enter the model name to confirm:
    </p>
    <code class="select-all ml-50">{{ name }}</code>
    <b-input id="confirmation-input-field" v-model="confirmationInput" class="mt-50" />
    <span class="font-weight-lighter text-secondary font-small-2">Input is case sensitive.</span>

    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="secondary" @click="cancel()">
        Cancel, keep model
      </b-button>
      <b-button
        id="delete-model-button"
        :disabled="!userConfirmed"
        :variant="!userConfirmed ? 'outline-danger' : 'danger'"
        size="sm"
        @click="ok()"
      >
        Yes, delete model
      </b-button>
  </template>
  </b-modal>
</template>

<script>
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import { computed, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'ModalDeleteModel',
  components: { BAlert },
  setup(props, context) {
    const id = computed(() => store.state.model.id)
    const name = computed(() => store.state.model.name)
    const confirmationInput = ref('')
    const userConfirmed = computed(() => confirmationInput.value === name.value)

    // Entity counts
    const requirementsCount = ref(0)
    const issuesCount = ref(0)
    const testsCount = ref(0)
    const behaviourNodesCount = ref(0)

    const onShow = () => {
      confirmationInput.value = ''
      coreService.modelApi.getModelStats(id.value)
        .then(response => {
          requirementsCount.value = response.requirements
          issuesCount.value = response.issues
          testsCount.value = response.tests
          behaviourNodesCount.value = response.behaviourNodes
        })
    }
    const onDelete = () => {
      if (userConfirmed) {
        coreService.modelApi.deleteModel(id.value)
          .then(response => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Model Deleted',
                text: `${name.value}`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            store.dispatch('model/closeWorkspace')
            router.push({ name: 'model_browse' })
          })
          .catch(e => {
            const message = `Failed to delete model: ${name.value}`
            console.error(`${message}: ${e.body.detail}`)
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: `${message}`,
                text: `${e.body.detail}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    return {
      id,
      name,
      requirementsCount,
      issuesCount,
      testsCount,
      behaviourNodesCount,

      userConfirmed,
      confirmationInput,
      onShow,
      onDelete,
    }
  },
}
</script>
