<template>
  <b-modal
    id="modal-unshare-public-model"
    title="Unshare Public Model"
    ok-title="Revoke public model access"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="outline-secondary"
    @ok="unshareModel"
  >
    <span>
      Explicitly shared users of this model will still be able to access and modify this model.
    </span>
    <p class="mt-1 font-weight-bold text-warning">
      Do you wish to proceed?
    </p>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'UnsharePublicModel',
  methods: {
    unshareModel() {
      const { modelId } = this.$route.params
      coreService.modelApi.updateModelProperties(modelId, {public: false})
        .then(response => {
          this.$store.commit('model/setPublicShareState', false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model access set to PRIVATE',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(data => {
          console.error(data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to set model to private access',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
