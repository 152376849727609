<template>
  <ValidationObserver ref="validationObserver" v-slot="{ invalid, handleSubmit }">
    <b-modal
      id="modal-update-model"
      size="lg"
      title="Model Details"
      ok-title="Update Model Details"
      ok-variant="success"
      :ok-disabled="invalid"
      cancel-title="Discard"
      cancel-variant="outline-secondary"
      @ok.prevent="onSubmit"
      @show="onShow"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group class="mb-0">
          <LabelWithHoverIcon
            label-string="Model name"
            popover-text="Only the model owners can change this value."
            class="mb-25"
          />
          <b-form-group
            label-for="v-model-name"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="modelName"
              name="Model name"
              rules="required|min:2"
            >
              <b-form-input
                id="v-model-name"
                v-model="txtName"
                :disabled="!txtModelOwners.includes($store.state.auth.id)"
                placeholder="Model name"
                :state="errors.length > 0 ? false:null"
                debounce="275"
                @update="validate"
                @focusout="validate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form-group>

        <b-form-group>
          <LabelWithHoverIcon label-string="Description" class="mb-25" />
          <b-form-textarea
            id="model-description"
            v-model="txtDescription"
            placeholder="Describe the model"
            rows="6"
            required
          />
        </b-form-group>

        <b-form-group>
          <LabelWithHoverIcon label-string="Scope" class="mb-25" />
          <b-form-textarea
            id="model-scope"
            v-model="txtScope"
            placeholder="Define the scope of the project"
            rows="6"
            required
          />
        </b-form-group>

        <b-form-group>
          <LabelWithHoverIcon
            label-string="Security classification"
            popover-text="The default security classification that will be used for all entities within this model. This can be overriden on individual entities."
            class="mb-25"
          />

          <b-form-select
            v-model="txtDefaultSecurityClassification"
            :options="securityOptions"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

export default {
  name: 'UpdateModelModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    LabelWithHoverIcon,
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params

    const loading = ref(false)
    const currentModelNameInDb = ref('')
    const txtName = ref('')
    const txtDescription = ref('')
    const txtScope = ref('')
    const txtModelOwners = ref([])
    const txtDefaultSecurityClassification = ref(store.state.model.defaultSecurityClassification)

    const securityOptions = computed(() => store.getters['constants/securityClassifications'])

    const onShow = () => {
      loading.value = true
      coreService.modelApi.getModel(modelId)
        .then(({ properties }) => {
          currentModelNameInDb.value = properties.name
          txtName.value = properties.name
          txtDescription.value = properties.description
          txtScope.value = properties.scope
          txtDefaultSecurityClassification.value = properties.default_security_classification
          txtModelOwners.value = properties.owners
        })
        .catch(error => {})
        .finally(() => { loading.value = false })
    }
    const onSubmit = () => {
      loading.value = true
      const params = {
        name: txtName.value,
        description: txtDescription.value,
        scope: txtScope.value,
        default_security_classification: txtDefaultSecurityClassification.value,
      }
      coreService.modelApi.updateModelProperties(modelId, params)
        .then(response => {
          currentModelNameInDb.value = response.name
          store.commit('model/updateModelDetails', response)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model details updated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          context.root.$bvModal.hide('modal-update-model')
        })
        .catch(response => {
          console.error(response.data)
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update model details',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    const validationObserver = ref(null)
    const validate = () => {
      if (txtName.value !== currentModelNameInDb.value) {
        validationObserver.value.validate()
          .then(isValid => {
            coreService.modelsApi.validateModelDetails(txtName.value)
              .then(data => { validationObserver.value.setErrors(data.errors) })
              .catch(e => console.error(e))
          })
      }
    }

    return {
      txtName,
      txtDescription,
      txtScope,
      txtDefaultSecurityClassification,
      txtModelOwners,
      securityOptions,

      validationObserver,
      validate,

      onShow,
      onSubmit,
    }
  },
}
</script>
